body,
#root {
	min-width: 100%;
	min-height: 100%;
	min-height: -webkit-fill-available;
	background-color: #fff;
}

body {
	min-height: 100vh;
	height: 100vh;
	min-height: -webkit-fill-available;
}
